import React from 'react';
import { graphql } from 'gatsby';

import { Box, Hidden } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useIntl } from 'gatsby-plugin-react-intl';

import { Container, Typography } from '../components/theme';
import { Layout, HeadSection, BlockHeadline } from '../components/layout';
import Seo from '../components/Seo';

import AssetList from '../components/AssetList';
import AssetGrid from '../components/AssetGrid';

function PressPage({ data }) {
  const intl = useIntl();

  const {
    allStrapiPressRelease: { nodes: allPressReleases },
    allStrapiPressNewsArticle: { nodes: allPressNewsArticles },
    allStrapiVideo: { nodes: allVideos },
    allStrapiPhoto: { nodes: allPhotos },
    allStrapiLogo: { nodes: allLogos },
    allStrapiScreenshot: { nodes: allScreenshots },
    allStrapiPoster: { nodes: allPosters },
    allStrapiOther: { nodes: allMisc },
    zippedLogosCollection: { downloadAllLogosURL },
    zippedScreenshotsCollection: { downloadAllScreenshotsURL },
    zippedPhotosCollection: { downloadAllPhotosURL },
    zippedPostersCollection: { downloadAllPostersURL },
    zippedOthersCollection: { downloadAllMiscURL },
  } = data;

  return (
    <Layout>
      <HeadSection>
        <BlockHeadline
          sizeRestriction="none"
          content={intl.formatMessage({ id: 'press.title' })}
          variant="h1"
          align="center"
        />
      </HeadSection>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.secondary,
          padding: '40px 0 0',
          [theme.breakpoints.up('sm')]: {
            padding: '60px 0 0',
          },
          [theme.breakpoints.up('md')]: {
            padding: '100px 0 0',
          },
        })}
      >
        <Container>
          <Box
            sx={(theme) => ({
              marginBottom: '50px',
              [theme.breakpoints.up('sm')]: {
                marginBottom: '60px',
              },
              [theme.breakpoints.up('md')]: {
                marginBottom: '100px',
              },
            })}
          >
            <Grid container>
              <Grid
                item
                sm={12}
                md={7}
                sx={(theme) => ({
                  marginBottom: '50px',
                  [theme.breakpoints.up('md')]: {
                    marginBottom: '0',
                  },
                })}
              >
                <Typography variant="body1">{intl.formatMessage({ id: 'press.welcomeText' })}</Typography>
              </Grid>
              <Hidden mdDown>
                <Grid item md={1} />
              </Hidden>
              <Grid item sm={12} md={4}>
                <Typography
                  variant="h3"
                  sx={(theme) => ({
                    fontSize: theme.typography.pxToRem(18),
                    fontStyle: 'italic',
                    textTransform: 'uppercase',
                  })}
                >
                  {intl.formatMessage({ id: 'press.contact' })}
                </Typography>
                <Typography variant="body1">{intl.formatMessage({ id: 'press.contactText' })}</Typography>
                <br />
                <Typography variant="body1">
                  <a href={`mailto:${intl.formatMessage({ id: 'press.email' })}`}>
                    {intl.formatMessage({ id: 'press.email' })}
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid container>
            {/* <AssetGrid
              col={3}
              size={138}
              assets={allMisc}
              zippedAssets={downloadAllMiscURL}
              title="press.worldrecord"
              fileSize
            /> */}
            <AssetList assets={allPressReleases} title="press.releases" />
            <AssetList assets={allPressNewsArticles} title="press.news" />
            <AssetGrid col={3} size={46} assets={allLogos} zippedAssets={downloadAllLogosURL} title="press.logos" />
            <AssetGrid
              col={4}
              size={138}
              assets={allScreenshots}
              zippedAssets={downloadAllScreenshotsURL}
              title="press.screenshots"
            />
            <AssetGrid
              col={3}
              size={138}
              assets={allPosters}
              zippedAssets={downloadAllPostersURL}
              title="press.posters"
              fileSize
            />
            <AssetList assets={allVideos} title="press.videos" />
            <AssetGrid col={3} size={65} assets={allPhotos} zippedAssets={downloadAllPhotosURL} title="press.photos" />
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
}

// language variable is needed, because we have to query all locales for logos
// it is inserted by gatsby-plugin-react-intl, so no need for custom context on page creation
// https://github.com/theowenyoung/gatsby-plugin-intl/blob/main/src/gatsby-node.js#L86
export const query = graphql`
  query AssetQuery($language: String) {
    allStrapiPressRelease: allStrapiAsset(
      sort: { order: DESC, fields: Date }
      filter: { Type: { eq: "press-release" }, locale: { eq: $language } }
    ) {
      nodes {
        assetId: id
        assetData: Data {
          id
          localFile {
            publicURL
          }
        }
        assetDate: Date
        assetTitle: Title
        assetUrl: URL
      }
    }
    allStrapiPressNewsArticle: allStrapiAsset(
      sort: { order: DESC, fields: Date }
      filter: { Type: { eq: "press-news-article" }, locale: { eq: $language } }
    ) {
      nodes {
        assetId: id
        assetData: Data {
          id
          localFile {
            publicURL
          }
        }
        assetDate: Date
        assetTitle: Title
        assetUrl: URL
      }
    }
    allStrapiVideo: allStrapiAsset(filter: { Type: { eq: "video" }, locale: { eq: $language } }) {
      nodes {
        assetId: id
        assetTitle: Title
        assetUrl: URL
      }
    }
    allStrapiPhoto: allStrapiAsset(filter: { Type: { eq: "photo" }, locale: { eq: $language } }) {
      nodes {
        assetId: id
        assetTitle: Title
        assetFields: fields {
          allAssets: allPhotos
        }
        assetSrc: Data {
          localFile {
            extension
            childrenImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
      }
    }
    allStrapiLogo: allStrapiAsset(filter: { Type: { eq: "logo" } }) {
      nodes {
        assetId: id
        assetTitle: Title
        assetSrc: Data {
          localFile {
            childrenImageSharp {
              gatsbyImageData
            }
            extension
            publicURL
          }
          name
        }
        assetFields: fields {
          allAssets: allLogos
        }
      }
    }
    allStrapiScreenshot: allStrapiAsset(filter: { Type: { eq: "screenshot" }, locale: { eq: $language } }) {
      nodes {
        assetId: id
        assetTitle: Title
        # assetFields: fields {
        #   allAssets: allScreenshots
        # }
        assetSrc: Data {
          localFile {
            childrenImageSharp {
              gatsbyImageData
            }
            extension
            publicURL
          }
        }
      }
    }
    allStrapiPoster: allStrapiAsset(filter: { Type: { eq: "poster" }, locale: { eq: $language } }) {
      nodes {
        assetId: id
        assetTitle: Title
        assetSrc: Data {
          localFile {
            extension
            publicURL
            size
          }
        }
        assetFields: fields {
          allAssets: allPosters
        }
        assetPreview: Preview_Image {
          localFile {
            childrenImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    allStrapiOther: allStrapiAsset(filter: { Type: { eq: "other" }, locale: { eq: $language } }) {
      nodes {
        assetId: id
        assetTitle: Title
        assetSrc: Data {
          localFile {
            extension
            publicURL
            size
          }
        }
        assetPreview: Preview_Image {
          localFile {
            childrenImageSharp {
              gatsbyImageData
            }
          }
        }
        assetFields: fields {
          allAssets: allOthers
        }
      }
    }
    zippedPhotosCollection {
      downloadAllPhotosURL: downloadAllURL
    }
    zippedLogosCollection {
      downloadAllLogosURL: downloadAllURL
    }
    zippedScreenshotsCollection {
      downloadAllScreenshotsURL: downloadAllURL
    }
    zippedPostersCollection {
      downloadAllPostersURL: downloadAllURL
    }
    zippedOthersCollection {
      downloadAllMiscURL: downloadAllURL
    }
  }
`;

export default PressPage;

export function Head() {
  return <Seo />;
}
