import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { useIntl } from 'gatsby-plugin-react-intl';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Box } from '@mui/material';

import path from 'path';

import formatBytes from '../utils/formatBytes';
import { Typography } from './theme';
import svgIcons from '../constants/svgIcons';

// svgThumbnail: {
//   display: 'block',
// },

const imageStyles = {
  alignContent: 'center',
  display: 'flex',
  height: 'calc(100% - 20px)',
  justifyContent: 'center',
  left: '10px',
  position: 'absolute',
  top: '10px',
  width: 'calc(100% - 20px)',
  '& img': {
    objectFit: 'contain !important',
  },
  '& svg': {
    height: '100%',
    objectFit: 'contain',
    width: '100%',
  },
  '& > div > div': {
    display: 'contents',
  },
};

const renderThumbnail = (image, title) => {
  if (image) {
    if (image[0].localFile.childrenImageSharp && image[0].localFile.childrenImageSharp.length > 0) {
      return <GatsbyImage key={title} image={image[0].localFile.childrenImageSharp[0].gatsbyImageData} alt={title} />;
    }
    if (image[0].localFile.publicURL.endsWith('svg')) {
      return <ReactSVG src={path.resolve(path.join(__dirname, image[0].localFile.publicURL))} />;
    }
  }
  return <>No Asset Found</>;
};

const renderPreviewThumbnail = (image, title) => {
  if (image) {
    if (image.localFile.childrenImageSharp.length > 0) {
      return <GatsbyImage key={title} image={image.localFile.childrenImageSharp[0].gatsbyImageData} alt={title} />;
    }
  }
  return <>No Asset Found</>;
};

const getFileEndings = (Image) => {
  const result = [];
  for (const image of Image) {
    result.push(image.localFile.extension.toUpperCase());
    result.push(' \u{00B7} ');
  }
  result.pop();
  return result;
};

function AssetGridItem({ assetTitle, assetSrc, assetPreview, assetFields, size, fileSize }) {
  const intl = useIntl();

  return (
    <Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
          borderRadius: '12px',
          boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.25)',
          height: '0',
          marginBottom: '25px',
          overflow: 'hidden',
          paddingBottom: size ? `${size}%` : '65%',
          position: 'relative',
        })}
      >
        {assetPreview ? (
          <Box sx={imageStyles}>{renderPreviewThumbnail(assetPreview, assetTitle, size)}</Box>
        ) : (
          <Box sx={imageStyles}>{renderThumbnail(assetSrc, assetTitle, size)}</Box>
        )}
      </Box>
      <Box
        sx={{
          paddingLeft: '42px',
          position: 'relative',
          '& svg': {
            left: '0',
            position: 'absolute',
            top: '3px',
          },
        }}
      >
        <ReactSVG wrapper="span" src={svgIcons.download} />
        <Typography variant="body1" component="div">
          {assetSrc.length === 1 ? (
            <a href={assetSrc[0].localFile.publicURL} download>
              {assetTitle}
            </a>
          ) : (
            <a href={assetFields.allAssets} download>
              {assetTitle}
            </a>
          )}
          <Box sx={(theme) => ({ color: theme.palette.grey[400] })}>
            {getFileEndings(assetSrc)}
            {fileSize && assetSrc.length === 1 && <>&nbsp;[{formatBytes(assetSrc[0].localFile.size, 1)}]</>}
          </Box>
        </Typography>
      </Box>
    </Box>
  );
}

AssetGridItem.propTypes = {
  fileSize: PropTypes.bool.isRequired,
};

export default AssetGridItem;
