import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-react-intl';

import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { BlockHeadline } from './layout';
import AssetGridItem from './AssetGridItem';

function AssetGrid({ assets, col, fileSize, size, title, zippedAssets }) {
  const intl = useIntl();
  const grid = 12 / col;

  return (
    <Grid
      item
      xs={12}
      sx={(theme) => ({
        marginBottom: '50px',
        [theme.breakpoints.up('sm')]: {
          marginBottom: '60px',
        },
        [theme.breakpoints.up('md')]: {
          marginBottom: '100px',
        },
        '& h2': {
          [theme.breakpoints.down('sm')]: {
            marginBottom: '10px',
          },
        },
      })}
    >
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={7} md={8}>
          <BlockHeadline sizeRestriction="none" content={intl.formatMessage({ id: title })} variant="h2" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          sx={(theme) => ({
            [theme.breakpoints.up('sm')]: {
              textAlign: 'right',
            },
          })}
        >
          <Box
            component="a"
            href={zippedAssets}
            sx={(theme) => ({
              color: theme.palette.secondary.main,
              display: 'inline-block',
              fontSize: theme.typography.pxToRem(18),
              fontWeight: '500',
              marginTop: '20px',
              textDecoration: 'none',
              '&:focus, &:hover': {
                textDecoration: 'underline',
              },
              [theme.breakpoints.down('lg')]: {
                marginTop: '12px',
              },
              [theme.breakpoints.down('sm')]: {
                marginBottom: '32px',
                marginTop: '0',
              },
            })}
            download
          >
            {intl.formatMessage({ id: 'downloadAll' })}
          </Box>
        </Grid>
      </Grid>

      {assets && (
        <Grid container spacing={4}>
          {assets.map((asset) => {
            return (
              <Grid item key={asset.assetId} xs={12} sm={6} lg={grid}>
                <AssetGridItem fileSize={fileSize} size={size} {...asset} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
}

AssetGrid.propTypes = {
  col: PropTypes.number,
  fileSize: PropTypes.bool,
  size: PropTypes.number,
};

AssetGrid.defaultProps = {
  col: 4,
  fileSize: false,
  size: 65,
};

export default AssetGrid;
