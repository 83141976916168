import React from 'react';
import { ReactSVG } from 'react-svg';
import { useIntl } from 'gatsby-plugin-react-intl';

import { Box } from '@mui/material';

import localeDateString from '../utils/localeDateString';
import { Typography, Link } from './theme';
import svgIcons from '../constants/svgIcons';

function AssetListItem({ assetTitle, assetDate, assetData, assetUrl }) {
  const intl = useIntl();
  console.log(assetData);

  return (
    <Box
      sx={{
        marginBottom: '18px',
        paddingLeft: '42px',
        position: 'relative',
        '& svg': {
          position: 'absolute',
          left: '0',
          top: '3px',
        },
      }}
    >
      <ReactSVG wrapper="span" src={assetUrl ? svgIcons.openIn : svgIcons.download} />
      <Typography variant="body1" component="div">
        <Link to={assetUrl || assetData[0].localFile.publicURL} target="_blank" rel="noreferrer">
          {assetTitle}
        </Link>
        <Box sx={(theme) => ({ color: theme.palette.grey[400] })}>
          {assetUrl && assetUrl.includes('youtube') ? (
            <>YouTube</>
          ) : (
            <>
              {localeDateString(assetDate)}
              {' \u{00B7} '}
              {assetUrl ? intl.formatMessage({ id: 'externalLink' }) : 'PDF'}
            </>
          )}
        </Box>
      </Typography>
    </Box>
  );
}

export default AssetListItem;
