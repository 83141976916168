import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import { Container } from '../theme';
import { sxPropType } from '../../proptypes/misc';

function HeadSection({ headline, children, sx, ...other }) {
  return (
    <Box
      sx={[
        (theme) => ({
          backgroundColor: theme.palette.background.default,
          padding: '10px 0 50px',
          [theme.breakpoints.up('md')]: {
            padding: '80px 0 100px',
          },
          textAlign: 'center',
          '& h1': {
            [theme.breakpoints.down('sm')]: {
              textAlign: 'left !important',
            },
          },
        }),
        headline &&
          ((theme) => ({
            padding: '95px 0 100px',
            [theme.breakpoints.down('sm')]: {
              padding: '90px 0',
            },
          })),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...other}
    >
      <Container>{children}</Container>
    </Box>
  );
}

HeadSection.propTypes = {
  headline: PropTypes.bool,
  children: PropTypes.node.isRequired,
  sx: sxPropType,
};

HeadSection.defaultProps = {
  headline: false,
  sx: [],
};

export default HeadSection;
