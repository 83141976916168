import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';

import Grid from '@mui/material/Unstable_Grid2';

import { Button } from './theme';
import { BlockHeadline } from './layout';
import Asset from './AssetListItem';

function AssetList({ assets, title }) {
  const intl = useIntl();
  const [showAll, setShowAll] = useState(false);

  return (
    <Grid
      item
      sm={12}
      sx={(theme) => ({
        marginBottom: '50px',
        [theme.breakpoints.up('sm')]: {
          marginBottom: '60px',
        },
        [theme.breakpoints.up('md')]: {
          marginBottom: '100px',
        },
      })}
    >
      <BlockHeadline sizeRestriction="normal" content={intl.formatMessage({ id: title })} variant="h2" />
      {assets && (showAll || assets.length <= 3) ? (
        <>
          {assets.map((asset) => {
            return <Asset key={asset.assetId} {...asset} />;
          })}
          {assets.length > 3 && (
            <Button color="secondary" variant="contained" onClick={() => setShowAll(false)} sx={{ marginTop: '14px' }}>
              {intl.formatMessage({ id: 'collapse' })}
            </Button>
          )}
        </>
      ) : (
        <>
          {assets.slice(0, 3).map((asset) => {
            return <Asset key={asset.assetId} {...asset} />;
          })}
          <Button color="secondary" variant="contained" onClick={() => setShowAll(true)} sx={{ marginTop: '14px' }}>
            {intl.formatMessage({ id: 'showAll' })}
          </Button>
        </>
      )}
    </Grid>
  );
}

export default AssetList;
